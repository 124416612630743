import http from "../utils/http"

/**
 * 支付详情
 * @param {object} params
 */
export function getPayInfo(params) {
    return http({
        url: "/api/pay/info",
        data: params,
        forceLogin: true
    })
}

/**
 * 支付方式
 * @param {object} params
 */
export function getPayType(params) {
    return http({
        url: "/api/pay/type",
        data: params,
        forceLogin: true
    })
}

/**
 * 支付状态
 * @param {object} params
 */
export function checkPayStatus(params) {
    return http({
        url: "/api/pay/status",
        data: params,
        forceLogin: true
    })
}

/**
 * 支付状态
 * @param {object} params
 */
export function pay(params) {
    return http({
        url: "/api/pay/pay",
        data: params,
        forceLogin: true
    })
}

/**
 * 订单查询完成支付
 * @param {Object} out_trade_no
 */
export function orderChecked(out_trade_no) {
	return http({
	    url: "/api/pay/checked",
	    data: {out_trade_no: out_trade_no},
	    forceLogin: true
	})
}
